<template lang="pug">
  .toggler
    AppIconButton.toggle-icon(:icon="icon" useAppIcon @click="toggle()")
</template>

<script>
  // misc
  import { some, values } from "lodash-es"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      togglers() {
        return this.$store.state[this.storeName]["togglers"]
      },

      storeName() {
        return isOrganizationWithSharedInventory() ? "sharedInventory" : "inventoryManagement"
      },

      hasCollapsedData({ togglers }) {
        return some(values(togglers), t => !t)
      },

      icon() {
        return this.hasCollapsedData ? "expand" : "collapse"
      }
    },

    methods: {
      toggle() {
        const { hasCollapsedData: value } = this

        this.$store.commit(`${this.storeName}/TOGGLE_ALL_TOGGLERS`, { value })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .toggler
    text-align: right
    margin-top: 10px

    .toggle-icon
      +icon-button($icon-color)
      width: 15px

      &:hover
        box-shadow: none
</style>
